












import { Component, Vue } from 'vue-property-decorator';
import { AppActionTypes } from '@/store/types';

@Component
export default class DownloadFile extends Vue {
    get url() {
        return `${process.env.VUE_APP_URL_API}/api/citas/show-pdf-agent/${
            this.$route.params.id
        }?authorization=${localStorage.getItem('token')}`;
    }

    mounted() {
        this.$store.dispatch(AppActionTypes.showLoadingAction);
    }

    load() {
        this.$store.dispatch(AppActionTypes.hideLoadingAction);
    }
}
